import http from '@/common/http-common';
import {CONFIG } from '@/common/constant';

class ConfigService {
  get(configType) {
    return http.get(`${CONFIG}/user/${configType}`)
  }

  getAdmin(configType) {
    return http.get(`${CONFIG}/admin/${configType}`)
  }


  update(config) {
    return http.post(`${CONFIG}`, config)
  }


}

export default new ConfigService();
